import React from 'react';
import Layout from '../components/layout';
import Contact from '../components/contact';
import SEO from '../components/seo';

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="contact-spacer">
      <Contact />
    </div>
  </Layout>
);
export default ContactPage;
